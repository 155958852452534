<template>
  <div id="businessFunnelId" style="width: 100%; height: 400px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'BusinessFunnel',
  props: {
    funnelList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    funnelList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},

  mounted() {},
  methods: {
    init(arr = []) {
      this.$nextTick(() => {
        var chartDom = document.getElementById('businessFunnelId')
        var myChart = echarts.init(chartDom)
        var option
        option = {
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              // params.data 是当前数据项
              const value = Number(params.data.values).toLocaleString()
              return `${params.name}： ${value}个`
            },
          },
          xAxis: {
            show: false,
            type: 'value',
          },
          yAxis: {
            show: false,
            type: 'value',
          },
          legend: {
            show: false,
          },
          grid: {
            top: 24,
            left: '2%',
            right: 20,
            bottom: 30,
            containLabel: true,
            borderWidth: 0.5,
          },
          series: [
            {
              top: 0,
              name: '',
              type: 'funnel',
              left: '10%',
              width: '80%',
              minSize: '40%',
              maxSize: '100%',
              sort: 'descending',
              label: {
                normal: {
                  show: true,
                  color: '#000',
                  fontSize: 20,
                  position: 'inside',
                  formatter: function (params) {
                    const value = Number(params.data.values).toLocaleString()
                    return `${params.name} : ${value}个 `
                  },
                },
              },
              data: arr.map((item, index) => ({
                ...item,
                itemStyle: {
                  normal: {
                    color: ['#fdcd45', '#11c26d', '#0064ff'][index % 3], // 自定义颜色
                  },
                },
              })),
            },
          ],
        }

        option && myChart.setOption(option)
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
